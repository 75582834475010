/**
 * hide scrollbars
 */
/*::-webkit-scrollbar {
	display: none;
	width: 2px !important;
}

.disable-scrollbars {
	scrollbar-width: thin; !* Firefox *!
	-ms-overflow-style: auto; !* IE 10+ *!
}*/

#root {
	width: 100%;
	min-width: 100%;
	height: 100%;
	min-height: 100%;
	padding: 0;
	margin: 0;
	overflow-x: hidden;
	position: relative;
}

.link {
	@apply text-blue-500;
}

.shadow-outline-red {
	box-shadow: 0 0 0 3px theme("colors.red.300");
}

.shadow-outline-green {
	box-shadow: 0 0 0 3px theme("colors.green.300");
}

.shadow-outline-white {
	box-shadow: 0 0 0 3px rgba(255, 255, 255, 1);
}

.shadow-outline-black {
	box-shadow: 0 0 0 3px rgba(0, 0, 0, 1);
}

.react-switch {
	vertical-align: middle;
	margin-left: 4px;
}

.react-icons {
	vertical-align: middle;
}

.z-1 {
	z-index: 1;
}

.z-2 {
	z-index: 2;
}

.bg-login-2 {
	background: url(../assets/bg-login-2.webp) no-repeat 0 0 fixed;
	background-size: cover;
}

.w-96 {
	width: calc(theme("spacing.1") * 96);
}

.w-128 {
	width: calc(theme("spacing.1") * 128);
}

/* social media colorsReducer */
.text-facebook {
	color: #365397;
}

.text-twitter {
	color: #00a9f1;
}

.text-github {
	color: #2f2f2f;
}

.text-linkedin {
	color: #006db3;
}

.text-apple {
	color: #737373;
}

.text-google {
	color: #4285f4;
}

.text-google-plus {
	color: #e0452c;
}

.text-youtube {
	color: #ff3333;
}

.text-vimeo {
	color: #48b6ed;
}

.text-pinterest {
	color: #ce1a19;
}

.text-yelp {
	color: #c30f00;
}

.text-dribbble {
	color: #ed4584;
}

.text-amazon {
	color: #ff9700;
}

.text-skype {
	color: #00acf4;
}

.text-instagram {
	color: #396d9a;
}

.text-dropbox {
	color: #0d84de;
}

.text-flickr {
	color: #ea0066;
}

.text-tumblr {
	color: #304c68;
}

.text-foursquare {
	color: #207dc5;
}

.error-message {
	@apply bg-red-500 text-white;
}

.success-message {
	@apply bg-green-500 text-white;
}

.form-border-error {
	@apply border border-red-500 !important;
}

.filter-select-width {
	max-width: 125px;
}

.chabot_content {

}

.chabot_content p {
	@apply mb-3;
}

.chabot_content ul, .chabot_content ol {
	padding-left: 25px;
}

.chabot_content ul li {
	list-style-type: circle;
}

.chabot_content ol li {
	list-style-type: decimal;
}

#chatContainer {
	height: calc(100vh - 400px);
}

